.container{
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    height: calc(100vh - 80px);
    background-image: url("../Media/bind_background.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    
}
.main{
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.lineup_selector{ 
   
    position: relative;
    width: 20%;
    height: calc(100vh - 8rem);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    overflow: visible;
    background: rgba(18, 31, 44, 0.253);
    border-right: 2px solid rgb(83, 83, 83);
}
.lineup_selector_main{
    overflow-y: auto;
    width: 100%;
    margin-left: 3rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-bottom: 4rem;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    border-top: 2px solid rgb(246,68,82);
    border-bottom: 2px solid rgb(246,68,82);
    background: rgba(18, 31, 44, 0.527);
    height: 100%;
}
.lineup_selector_main::after{
    content: '';
    height: 1rem;
    width: 1rem;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgb(246,68,82);
}

.lineup_detail{
    max-width: 80%;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;

	
}
.map_title{
    font-size: 3rem;
    font-family: 'ValorantFont', sans-serif;
}

.filter{
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.lineupDetailItem{
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    
}

@media only screen and (max-width: 1500px) {
    .lineup_selector{
        min-width: 250px;
    }
  }
  @media only screen and (max-width: 700px) {
      .container{
          background: rgb(235,231,228);
      }
      .lineup_selector{
        z-index: 500;
          display: none;
          position: absolute;
          width: 80vw;
          height: calc(100vh - 23rem);
      }
      .lineup_detail{
          max-width: 100%;
      }
      .lineup_selector_main{
          margin-left: 0;
      }
      .filter{
          background: rgb(129, 129, 129);
      }
  }
  @media only screen and (max-height: 800px){
      .container{
        background: rgb(235,231,228);
      }
  }
  
