
.container{
    height: 13rem;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgba(73, 69, 69, 0.699); 
  background-blend-mode: multiply;
}
.main{
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
-webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;
position: relative;
}
.map{
    border-bottom: 1px solid rgb(102, 102, 102);
    width: 12.9rem;
    letter-spacing: 2px;
    text-align: center;
    font-size: 2.5rem;
    margin: 0;
    color: rgb(226, 226, 226);
    font-family: 'DrukWide';
    transform: rotate(-90deg);
    transform-origin: right, top;
    -ms-transform: rotate(-90deg);
    -ms-transform-origin:right, top;
    -webkit-transform: rotate(-90deg);
    -webkit-transform-origin:right, top;
}
.line{
    height: 100%;
    border-left: 1px solid rgb(145, 145, 145);
    margin-left: 8rem;
}
.title{
    color: rgb(218, 218, 218);
    font-size: 4rem;
    font-weight: 300;
    width: 40rem;
    font-family: "DrukWide", 'Roboto', sans-serif;
    border-bottom: 1px solid rgb(145, 145, 145);
    letter-spacing: 3px;
    padding-left: 2rem;
}
.location{
    color:rgb(235, 88, 100);
    font-size: 1.5rem;
    font-family: "Roboto";
    margin-bottom: 0;
    line-height: 100%;
    padding-left: 2rem;
    
}
.button{
    width: 8rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: absolute;
    display: none;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    margin-left: 2rem;

}
.icon {
	width: 35px;
	height: 5px;
	background-color: rgb(246,68,82);
	margin: 6px 0;
	z-index: 5001;
  }
@media only screen and (max-width: 1000px) {
    .line{
        margin-left: 3rem;
    }
   }
   @media only screen and (max-width: 700px) {
    .line{
        margin-left: 8rem;
    }
    .button{
        display: block;
    }
   }
   @media only screen and (max-width: 550px){
    .line{
        margin-left: 6rem;
    }
        .title{
            font-size: 2rem;
        }
        .container{
            height: 5rem
        }
        .icon{
            width: 25px;
        }
        .button{
            width: 5rem;
        }
   }
   @media only screen and (max-width: 349px) {
       .location{
        padding-left: 0rem;
        font-size: 1.2rem;
       }
       .title{
           margin: 0;
       }
       .container{
           height: 5rem;
       }
       .title{
           font-size: 2.2rem;
       }
   }