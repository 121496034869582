html,
body {
	overflow-x: hidden;
	height: 100%;
	font-size: 62.5%; /* Now 10px = 1rem! */
	background-color: rgb(236, 232, 225);
}
:root {
	--main-white-color: rgb(235, 231, 228);
	--main-red-color: rgb(255, 70, 85);
}
