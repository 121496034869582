
.container {
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	position: relative;
	scale: 1;
	z-index: 15;
	height: 47rem;
	width: 25rem;
	margin: 1.5rem;
	-webkit-clip-path: polygon(6% 0, 100% 0, 100% 100%, 94% 100%, 0 100%, 0 3%);
	        clip-path: polygon(6% 0, 100% 0, 100% 100%, 94% 100%, 0 100%, 0 3%);
}
.main {
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.397);
}
.info {
	-webkit-transform-origin: bottom right;
	    -ms-transform-origin: bottom right;
	        transform-origin: bottom right;
	text-align: right;
	-webkit-transform: rotate(-90deg);
	    -ms-transform: rotate(-90deg);
	        transform: rotate(-90deg);
	border-right: 1px solid white;
	position: absolute;
	width: 30rem;
	top: -5rem;
	right: 0;
}
.map_title {
	text-decoration: none !important;
	font-size: 5rem;
	color: rgba(0, 0, 0, 0);
	font-family: "DrukWide", sans-serif;
	-webkit-text-stroke: 1.5px rgb(241, 241, 241);
	margin: 0;
	padding-right: 1rem;

}
.map_location {
	padding-right: 1rem;
	position: relative;
	bottom: -1rem;
	font-size: 2rem;
	font-weight: 300;
	letter-spacing: 2px;
	font-family: "Roboto", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
		'Helvetica Neue', sans-serif;
	color: rgb(255, 70, 85);
	border-bottom: 1px solid white;
}

h1::before {
	position: absolute;
	content: attr(data-content);
	text-decoration: underline;
	-webkit-clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
	        clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
	-webkit-transition: -webkit-clip-path 275ms ease;
	transition: -webkit-clip-path 275ms ease;
	-o-transition: clip-path 275ms ease;
	transition: clip-path 275ms ease;
	transition: clip-path 275ms ease, -webkit-clip-path 275ms ease;
	-webkit-text-decoration-color: rgb(255, 70, 85);
	        text-decoration-color: rgb(255, 70, 85);
}
.main:hover h1::before {
	-webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
	        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}
.box {
	height: 4rem;
	width: 4rem;
	bottom: 0rem;
	position: absolute;
	border: 1px solid #cfcfcf;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgb(255, 70, 85)), to(rgb(255, 70, 85)));
	background-image: -o-linear-gradient(rgb(255, 70, 85), rgb(255, 70, 85));
	background-image: linear-gradient(rgb(255, 70, 85), rgb(255, 70, 85));
	background-position: 0% 100%;
	background-repeat: no-repeat;
	background-size: 0% 100%;
	-webkit-transition: background-size .6s, color .6s;
	-o-transition: background-size .6s, color .6s;
	transition: background-size .6s, color .6s;
}
.main:hover + .box {
	background-size: 100% 100%;
}
.box::before {
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	content: "";
	display: block;
	position: absolute;
	height: .1rem;
	width: 100%;
	border-top: 1px solid #b5b9b6;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	    transform: rotate(45deg);
	top: 50%;
}
.box::after {
	content: "";
	display: block;
	position: absolute;
	height: .8rem;
	width: .8rem;
	background-color: #0f1923;
	top: calc(50% - .5rem);
	left: calc(50% - .5rem);
	border: 1px solid #ece8e1;
}
@media only screen and (max-width: 700px) {
	.info {
		-webkit-transform: rotate(0);
		    -ms-transform: rotate(0);
		        transform: rotate(0);
		margin-right: 2rem;
		top: 0;
	}
	.container {
		height: 25rem;
	}
	.map_title {
		font-size: 3rem;
	}
	.map_location {
		font-size: 1.6rem;
	}
}