
.container {
	width: 90%;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	max-height: 190px !important;
	background: rgb(15,25,35);
	margin-top: 1rem;
	opacity: 1;
	-webkit-clip-path: polygon(3% 0, 100% 0, 100% 100%, 0 100%, 0 5%);
	        clip-path: polygon(3% 0, 100% 0, 100% 100%, 0 100%, 0 5%);
	background-image: -o-linear-gradient(315deg, rgb(202, 58, 70) 50%, transparent 51%);
	background-image: linear-gradient(135deg, rgb(202, 58, 70) 50%, transparent 51%);
	background-size: 100px 100px; /* some initial size to get the slanted appearance */
	background-position: -50px -50px; /* negative positioning to hide it initially */
	background-repeat: no-repeat;
	-webkit-transition: all ease 0.8s;
	-o-transition: all ease 0.8s;
	transition: all ease 0.8s;
}
.container:hover {
	background-size: 200% 200%; /* 200% because gradient is colored only for 50% */
	background-position: 0px 0px; /* bring it fully into view */
	color: #fff;
	cursor: pointer;
}
.background {
	background-color: white;
	height: 0%;
	width: 100%;
	position: absolute;
}
.essential_icon {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
		'Helvetica Neue', sans-serif;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	font-weight: 400;
	letter-spacing: 1px;
	padding: 1px 3px 1px 3px;
	border-radius: 3px;
	color: white;
	background-color: rgb(53, 142, 197);
	font-size: 1.2rem;
}
.title {
	padding-left: .5rem;
	font-family: 'GoodTimes';
	font-weight: 300;
	font-size: 1.5rem;
	margin-top: 1rem;
	margin-bottom: 0rem;
	font-weight: 300;
	letter-spacing: 2px;
}
.main {
	border-left: 1px solid rgba(177, 177, 177, 0.863);
	margin-left: 2rem;
	padding-bottom: 2rem;
	padding-right: .5rem;
	height: 100%;
	color: rgb(236, 230, 230);
}

.active {
	background-color: rgb(202, 58, 70);
}
.attacking {
	background-color: rgb(255, 123, 0);
}
.defending {
	background-color: rgb(99,56,126);
}
.filters {
	padding-left: .4rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	-webkit-box-pack: start;
	    -ms-flex-pack: start;
	        justify-content: flex-start;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	font-family: "GoodTimes";
	border-bottom: 1px solid rgba(177, 177, 177, 0.863);
}
.filters > div {
	margin-right: .4rem;
}
.info_text {
	padding: .3rem 0 0 .5rem;
	font-family: "Interstate", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
	'Helvetica Neue', sans-serif;
	margin: 0;
	font-weight: 300;
	font-size: 1.4rem;
}

	@media only screen and (max-width: 600px){
		.container{
			height: -webkit-fit-content !important;
			height: -moz-fit-content !important;
			height: fit-content !important;
	max-height: 165px !important;

		}
	}