/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.container{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.difficulty_level{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 400;
    letter-spacing: 1px;
    padding: 1px 3px 1px 3px;
    border-radius: 5px;
    color: white;
    font-size: 1.2rem;
}
.colorGreen{
    background-color: rgb(98, 163, 29);
}
.colorYellow{
    background-color: rgb(163, 157, 94);
}
.colorRed{
    background-color: rgb(175, 41, 41);
}