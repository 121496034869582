
.container{
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-image: url("../Media/logs_background.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    min-height: calc(100vh - 12.1rem);
    
}
.container::before{
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(80, 80, 80, 0);

}
.main{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    width: 100%;
    
}
.left{
    width: 20%;
    height: 100%;
}
.right{
    width: 20%;
    height: 100%;
}
.middle{
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    position: relative;
    background: rgba(0, 0, 0, 0.575);
    min-height: calc(100vh - 8rem);
    height: 100%;
    width: 60%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    border-left: 1px solid rgb(197, 197, 197);
    border-right: 1px solid rgb(197, 197, 197);
}
.whiteLine{
    height: 100%;
    z-index: 0;
    position: absolute;
    margin-left: 7rem;
    border-left: 1px solid rgb(110, 110, 110);
}
.header{
    color: rgb(223, 223, 223);
    font-size: 5rem;
    margin-left: 7rem;
    padding-left: .5rem;
    margin-top: 7rem;
    position: relative;
    padding-right: 4rem;
    
    font-family: "DrukWide", sans-serif;
}
.content{
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding-left: 7rem;
}
.content_info{
    margin-top: 7rem;
    width: 90%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-right: 3rem;
    color: rgb(214, 214, 214);
    font-family: "Roboto", sans-serif;
    font-size: 1.6rem;
    line-height: 3rem;
}
.content_logs{
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding-top: 5rem;
    width: 100%;

    border-top: 1px solid grey;
    border-left: 1px solid grey;
    background: rgba(0, 0, 0, 0.658);
}
.log_container{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    padding-bottom: 5rem;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
}
@media only screen and (max-width: 1400px){

   .left{
       width: 10%;
   }
   .right{
       width: 10%;
   }
   .middle{
       width: 80%;
   }
}
@media only screen and (max-width: 900px){

    .content{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
 

    .header{
        margin-top: 3rem;
    }
    .content_info{
        margin-top: 3rem;
        font-size: 1.4rem;
    }
    .content_logs{
        padding-top: 2rem;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
}
@media only screen and (max-width: 600px){
    .container{
        height: 100%;
    }
    .left{
        width: 5%;
    }
    .right{
        width: 5%;
    }
    .middle{
        width: 90%;
    }
    .content{
        padding-left: 0rem;
    }
    .whiteLine{
        display: none;
    }
    .header{
        margin-left: 0rem;
        font-size: 2rem;
        margin-top: 1rem;
    }
    .content_info{
        margin-top: 1rem;
        width: 100%;
        line-height: 2.2rem;
        font-size: 1.4rem;
    }
    .content_logs{
        width: 100%;
        font-size: 1rem;
    }
}
@media only screen and (max-width: 500px){
    .container{
        min-height: calc(100vh - 4.5rem);
    }
    .middle{
        min-height: calc(100vh - 4.5rem);
    }
}